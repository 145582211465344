import { FC, FocusEvent, useCallback, useEffect, useState } from 'react';
import { NumericFormat } from 'react-number-format';
import { useFormRendererInfo } from '../../contexts/FormRendererContext';
import { SupportedLanguage, systemDefaultLanguageCode } from '../../types/Languages';
import CurrencyDropdown from './CurrencyDropdown';
import { Input, InputProps, InputStyle } from './form-control/Input';
import XIcon from './icon/XIcon';
import CurrencyList from 'currency-list';
import { DropdownSize } from './form-control/DropdownSelect';

type Props = {
  value: { currencyCode: string; amount: string };
  placeholder?: string;
  disabled?: boolean;
  canChange: boolean;
  onChange: (values: { currencyCode: string; amount: string }) => void;
  style?: InputStyle;
  isFixedPosition?: boolean;
};

const CurrencyInput: FC<Props> = (props) => {
  const { value, onChange, placeholder, disabled, canChange, style = InputStyle.NORMAL, isFixedPosition } = props;
  const [internalValue, setInternalValue] = useState(value.amount);
  const { language = systemDefaultLanguageCode } = useFormRendererInfo();

  useEffect(() => {
    setInternalValue(value.amount);
  }, [value]);

  const onAmountChangeInternal = useCallback((value: string) => {
    setInternalValue(value);
  }, []);

  const onAmountChange = useCallback(
    (amount: string, currencyCode?: string) => {
      // Add decimal places
      if (amount) {
        const requiredDecimalPlaces = CurrencyList.get(currencyCode || value.currencyCode).decimal_digits;

        const dotIndex = amount.indexOf('.');
        if (dotIndex === -1 && requiredDecimalPlaces > 0) {
          amount = amount + '.' + new Array(requiredDecimalPlaces).fill(0).join('');
        } else if (dotIndex !== amount.length - 1 - requiredDecimalPlaces) {
          const amountOfZerosToAdd = -1 * (amount.length - 1 - requiredDecimalPlaces - dotIndex);
          if (amountOfZerosToAdd > 0) {
            amount = amount + new Array(amountOfZerosToAdd).fill(0).join('');
          } else if (dotIndex > -1) {
            amount = amount.slice(0, amountOfZerosToAdd + (requiredDecimalPlaces ? 0 : -1));
          }
        }
      }
      setInternalValue(amount);
      onChange({ currencyCode: currencyCode || value.currencyCode, amount });
    },
    [onChange, value.currencyCode],
  );

  const onCurrencyCodeChange = useCallback(
    (currencyCode: string) => {
      onAmountChange(value.amount, currencyCode);
    },
    [onAmountChange, value.amount],
  );

  const onBlur = useCallback(() => {
    onAmountChange(internalValue);
  }, [internalValue, onAmountChange]);

  return (
    <NumericFormat
      value={internalValue}
      valueIsNumericString
      thousandSeparator=" "
      onValueChange={({ value }) => onAmountChangeInternal(value)}
      allowedDecimalSeparators={['.', ',']}
      decimalScale={CurrencyList.get(value.currencyCode).decimal_digits}
      customInput={InternalInput}
      canChangeCurrency={canChange}
      language={language}
      currencyCode={value.currencyCode}
      onCurrencyCodeChange={onCurrencyCodeChange}
      onAmountChange={onAmountChange}
      onBlur={onBlur}
      placeholder={placeholder}
      disabled={disabled}
      style={style}
      isFixedPosition={isFixedPosition}
    />
  );
};

type InternalInputProps = {
  language: SupportedLanguage;
  currencyCode: string;
  canChangeCurrency?: boolean;
  onCurrencyCodeChange: (code: string) => void;
  onAmountChange: (value: string) => void;
  disabled?: boolean;
  style?: InputStyle;
  isFixedPosition?: boolean;
};

const InternalInput: FC<InputProps & InternalInputProps> = (props) => {
  const { language, currencyCode, onCurrencyCodeChange, onAmountChange, canChangeCurrency, disabled, style, isFixedPosition, ...rest } = props;
  const [hasFocus, setHasFocus] = useState(false);

  const clearInput = useCallback(() => {
    onAmountChange('');
  }, [onAmountChange]);

  const onFocusInternal = useCallback(
    (event: FocusEvent<HTMLInputElement>) => {
      setHasFocus(true);
      rest.onFocus && rest.onFocus(event);
    },
    [rest],
  );

  const onBlurInternal = useCallback(
    (event: FocusEvent<HTMLInputElement>) => {
      setHasFocus(false);
      rest.onBlur && rest.onBlur(event);
    },
    [rest],
  );

  return (
    <div className="flex items-center">
      {canChangeCurrency ? (
        <CurrencyDropdown
          language={language}
          selectedCode={currencyCode}
          align="left"
          onChange={onCurrencyCodeChange}
          className={`rounded-r-none ${hasFocus ? 'border-r-accent-1' : ''}`}
          disabled={disabled}
          size={style === InputStyle.MINIMAL ? DropdownSize.S : DropdownSize.M}
          isFixedPosition={isFixedPosition}
        />
      ) : (
        <div
          className={`border-primary-1 flex-shrink-0 rounded-l-lg border-2 px-3 ${style === InputStyle.MINIMAL ? 'text-dpm-14 py-1' : 'py-2'} font-medium`}
        >
          <span className="text-gray-2">{currencyCode}</span>{' '}
          <span className="text-primary-1">{CurrencyList.get(currencyCode, language).symbol}</span>
        </div>
      )}
      <Input
        {...rest}
        style={style}
        onFocus={onFocusInternal}
        onBlur={onBlurInternal}
        wrapperClassName={`border-l-0 rounded-l-none ${rest.wrapperClassName}`}
        disabled={disabled}
      >
        <Input.Slot name="trailing">{hasFocus && <XIcon className="mr-2 h-4 w-4" onClick={clearInput} />}</Input.Slot>
      </Input>
    </div>
  );
};

export default CurrencyInput;
